const getFedUrl = () => {
    return getEnv() === 'production' ? "https://idpdecathlon.oxylane.com" :
        "https://preprod.idpdecathlon.oxylane.com";
}

const getApiParameters = () => {
    const DEFAULT_URL = 'https://parameter-api-dev.ig1.mdm-staging.kdm-mdm-staging-7nwv.decathlon.io/dev';
    const FED_CLIENT_URLS = new Map();
    FED_CLIENT_URLS.set('development', DEFAULT_URL);
    FED_CLIENT_URLS.set('preprod', 'https://parameter-api.ig1.mdm-staging.kdm-mdm-staging-7nwv.decathlon.io/preprod');
    FED_CLIENT_URLS.set('prod', 'https://parameter-prod-dot-mobile-retail-prod-eu-wtpk.appspot.com/prod');
    return FED_CLIENT_URLS.get(getEnv()) || DEFAULT_URL;
}

const getEnv = () => {
    return process.env.REACT_APP_STAGING || "development";
}

const getFedClientIdByEnv = () => {
    const FED_CLIENT_IDS = new Map();
    FED_CLIENT_IDS.set('development', 'C8b440f3b0a7cd6919c34d3a94d0015a064850ef8');
    FED_CLIENT_IDS.set('preprod', 'C58e1c4b8e3f87351fca8e1deba17e0134ea28976');
    FED_CLIENT_IDS.set('prod', 'Cf1c15b4c2caab4368a70fab5874238d63c0391da');
    const clientId = process.env.REACT_APP_CLIENT_ID || FED_CLIENT_IDS.get(getEnv());
    console.log("clientId: " + clientId);
    return clientId
}

const getClientIDAndEnv = () => {
    return {clientId: getFedClientIdByEnv(), env: getEnv()}
}

module.exports = { getFedUrl , getApiParameters, getClientIDAndEnv, getFedClientIdByEnv, getEnv }