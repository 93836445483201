const ACCESS_TOKEN_KEY = "access_token";

export default {
    ACCESS_TOKEN_KEY,
    getToken() {
        return localStorage.getItem(ACCESS_TOKEN_KEY) ? JSON.parse(localStorage.getItem(ACCESS_TOKEN_KEY)).access_token : '';
    },
    getUser() {
        return localStorage.getItem(ACCESS_TOKEN_KEY) ? JSON.parse(localStorage.getItem(ACCESS_TOKEN_KEY)) : undefined;
    }
}