import {UserManager} from 'oidc-client';
import {Component} from "react";
import Urls from '../../utils/urls';
import AuthUtils from './AuthUtils';

const FED_CONFIG = {
    clientId: Urls.getClientIDAndEnv().clientId,
    fedUrl: Urls.getFedUrl()
}

const CONNECTION_CONFIG = {
    authority: FED_CONFIG.fedUrl,
    client_id: FED_CONFIG.clientId,
    redirect_uri: window.location.origin,
    response_type: 'code',
    scope: 'openid profile',
    filterProtocolClaims: true,
    loadUserInfo: true
};

class Auth extends Component {

    UNSAFE_componentWillMount() {
        if (needRefresh){
            const userManager = new UserManager(CONNECTION_CONFIG);

            userManager.signinRedirectCallback().then(user => {
                localStorage.setItem(AuthUtils.ACCESS_TOKEN_KEY, JSON.stringify(user));
                window.location.href = window.location.origin + user.state;
            }).catch(e => {
                if(!alreadyConnected()) {
                    userManager.signinRedirect({state: window.location.pathname + window.location.search});
                }
            });
        }
    }

    render() {
        return (this.props.children);
    }

}

const alreadyConnected = () => {
    return AuthUtils.getToken() !== '' && !needRefresh();
}

const needRefresh = () => {
    return AuthUtils.getToken() !== '' ?
        AuthUtils.getToken().expires_at - Math.round(new Date().getTime()/1000) < 0 :
        true;
}

export default (Auth);