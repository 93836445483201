import React from 'react'

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import './App.css'
import './component/layout'
import Layout from './component/layout'
import { BrowserRouter } from 'react-router-dom';

import Auth from './component/auth/Auth';
import AuthUtils from './component/auth/AuthUtils';
import {MaterialApp} from '@dktunited/kdm-mdm_react-dktlabs';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    marginTop: 0,
    zIndex: 1,
    overflow: 'scroll',
    backgroundColor: 'grey',
  },
  container : {
    backgroundColor : 'grey'
  }
})


class App extends React.Component {

  render() {
    const { classes } = this.props;

    return (
    <div className={classes.root}>
      <BrowserRouter>
        <MaterialApp >
          <Auth>
            <Layout token={AuthUtils.getToken()} className={classes.container} />
          </Auth>
        </MaterialApp>
      </BrowserRouter>
    </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);