import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';

import ManageParams from './ManageParams'
import Headers from './Header'

import urls from '../utils/urls'
import OauthUtils from './auth/AuthUtils';

const styles = theme => ({
  container: {
    backgroundColor: 'grey',
    minHeight : "100%"
  }
});


class Layout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataUserFed: null,
            displayLoader : false
        };
    }


    fetchApi () {
      this.setState({displayLoader:true})

      const urlFedPrprod = urls.getFedUrl()

      let options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token,
        }
      }

      fetch(urlFedPrprod, options)
      .then(response => {
          if (!response.ok) {
              throw Error("Network request failed")
          }
          console.log(JSON.stringify(response))
          return response
      })
      .then(d => d.json())
      .then(d => {

          this.setState({
              dataUserFed: d,
              displayLoader: false
          })
      })
  }

    componentDidMount() { 
      this.fetchApi()
    }

    componentWillUnmount() { }

    render() {

        const token = this.props.token;
        const userInfo = OauthUtils.getUser();

        let fedInformation = <div> No user information available </div>



        if(userInfo && userInfo.profile) {
            fedInformation = <Headers userInfo={userInfo.profile}/>
        }

        return (
            
            <Container maxWidth="lg" >
              {fedInformation}
              <ManageParams token={token} app='com.decathlon.android.decathlonservices'/>
              <ManageParams token={token} app='com.oxit.mdm'/>
            </Container>
         
        )
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles) (Layout);
