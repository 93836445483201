import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import Paper from '@material-ui/core/Paper';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Modal from '@material-ui/core/Modal';

import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Grid from '@material-ui/core/Grid';

import urls from '../utils/urls'

const style = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650,
    paddingLeft: theme.spacing(3)
  },
  actionForm: {
    paddingLeft: theme.spacing(3)
  }, fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  modalParam: {
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  }, 
  right: {
    marginLeft: 'auto'
  },
  pointerClic : {
    cursor : 'pointer'
  },
  hiden : {
    visibility: 'hidden'
  },
  textField : {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
});


class ManageParams extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dataUserFed: null,
      displayLoader: false,
      app: props.app,
      paramApp: [],
      paramsVersion: [],
      paramsValue: [],
      rowsPerPage: 10,
      page: 0,
      ModalParamOpen: false,
      ModalParamVersionOpen : false,
      paramToSubmit: {}
    };

    this.fetchApi = this.fetchApi.bind(this)

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.displayParamVersion = this.displayParamVersion.bind(this)
    this.displayParamValue = this.displayParamValue.bind(this)

    this.displayModalParam = this.displayModalParam.bind(this)

    this.handleParamInput = this.handleParamInput.bind(this)
    this.submitParam = this.submitParam.bind(this)
    this.deleteParam = this.deleteParam.bind(this)

  }

  fetchApi() {

    this.setState({ displayLoader: true })

    const urlparams = urls.getApiParameters()+'/list/params/' + this.state.app

    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

    fetch(urlparams, options)
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {

        if (d.data != null) {
          this.setState({
            paramApp: d.data,
            displayLoader: false,
            parameterSelected: null,
            versionSelected: null
          })
        }

      })
  }


  // /version/params/:app/:param_uid/appVersion

  fetchParamVersion(paramToDisplay) {
    this.setState({ displayLoader: true })
    console.log("fetchParamVersion " + paramToDisplay)
    const urlparams = urls.getApiParameters()+'/version/params/' + this.state.app + '/' + paramToDisplay + '/appVersion'

    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

    fetch(urlparams, options)
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {


        const filtrerParamInList = (obj) => {
          if (obj.param_uuid === paramToDisplay) {
            return true;
          } else {
            return false;
          }
        }

        if (d.data != null) {
          this.setState({
            paramsVersion: d.data,
            displayLoader: false,
            parameterSelected: this.state.paramApp.filter(filtrerParamInList)[0],
            versionSelected: null
          })
        } else {
          this.setState({
            paramsVersion: [],
            displayLoader: false,
            parameterSelected: this.state.paramApp.filter(filtrerParamInList)[0],
            versionSelected: null
          })
        }

      })
  }


  fetchParamValue(paramToDisplay) {
    this.setState({ displayLoader: true })
    console.log("fetchParamValue " + paramToDisplay)
    const urlparams = urls.getApiParameters()+'/param/' + paramToDisplay

    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

    fetch(urlparams, options)
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {


        const filtrerVersionInList = (obj) => {
          if (obj.value_uuid === paramToDisplay) {
            return true;
          } else {
            return false;
          }
        }

        if (d.data != null) {
          this.setState({
            paramsValue: d.data,
            displayLoader: false,
            versionSelected: this.state.paramsVersion.filter(filtrerVersionInList)[0]
          })
        } else {
          this.setState({
            paramsValue: [],
            displayLoader: false,
            versionSelected: this.state.paramsVersion.filter(filtrerVersionInList)[0]
          })
        }

      })
  }


  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage(event) {

    this.setState({
      page: 0,
      rowsPerPage: event.target.value
    })

  }


  displayParamVersion(param) {

    console.log("displayParamVersion " + param)

    // clean le tableau des valeurs
    this.setState({
      paramsValue: []
    })

    this.fetchParamVersion(param)

  }

  displayParamValue(param) {

    console.log("displayParamValue " + param)
    this.fetchParamValue(param)

  }


  openModalParam = () => {

    this.setState({
      ModalParamOpen: true
    })

  }

  openModalParamVersion = () => {

    this.setState({
      ModalParamVersionOpen: true
    })

  }

  openModalParamValue = () => {

    this.setState({
      ModalParamValueOpen: true
    })

  }

  handleCloseModalparam = () => {
    this.setState({
      ModalParamOpen: false
    })
  }

  handleCloseModalParamVersion = () => {
    this.setState({
      ModalParamVersionOpen: false
    })
  }

  handleCloseModalParamValue = () => {
    this.setState({
      ModalParamValueOpen: false
    })
  }


  // Handle Inpuit Generique
  handleParamInput = (event) => {
    // console.log("handleParamInput : " +event.target.id + "|" + event.target.value)
    this.setState({
      [event.target.id] : event.target.value
    })
  }

  submitParam = (event) => {
    event.preventDefault()

    let dataToSubmit = {
      "app" : this.state.app,
      "key" : this.state.ParamInputkey,
      "desc" : this.state.ParamInputDesc,
      "type" : this.state.ParamInputType
    }

    console.log("submit " + JSON.stringify(dataToSubmit))

    const urlparams = urls.getApiParameters()+'/param/'

    let options = {
      method: 'POST',
      body:    JSON.stringify(dataToSubmit),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

      fetch(urlparams, options)
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {

        // Reset formulaire et close Modal
        this.setState({
          ModalParamOpen: false,
          ParamInputkey : null ,
          ParamInputDesc : null ,
          ParamInputType : null
        })

        // Appel Api to reload the table NOT EFFICIENT WAY ...
        this.fetchApi()

      })

  }


  deleteParam = (param_uuid) => {
    
    const urlparams = urls.getApiParameters()+'/param/'+param_uuid

    let options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

      fetch(urlparams, options)
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {

        // Appel Api to reload the table NOT EFFICIENT WAY ...
        this.fetchApi()

      })

  }

  displayModalParam = (app) => {

    console.log("display modal " + app)
    const { classes } = this.props;

    return (

      <Modal
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        open={this.state.ModalParamOpen}
        onClose={this.handleCloseModalparam}
      >
        <div className={classes.modalParam}>


          <Card className={classes.card}>
            <CardHeader
              title="Add new parameter"
            />
            <CardContent>
              <form className={classes.container} noValidate autoComplete="off" >

                <TextField
                  inputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  id="PackageName"
                  label="PackageName"
                  className={classes.textField}
                  value={this.state.app}
                  onChange={this.handleParamInput}
                  margin="normal"
                />
                <TextField
                  id="ParamInputkey"
                  label="Key"
                  className={classes.textField}
                  value={this.state.ParamInputkey}
                  onChange={this.handleParamInput}
                  margin="normal"
                />
                <TextField
                  id="ParamInputType"
                  label="type"
                  className={classes.textField}
                  value={this.state.ParamInputType}
                  onChange={this.handleParamInput}
                  margin="normal"
                />
                <TextField
                  id="ParamInputDesc"
                  label="description"
                  className={classes.textField}
                  value={this.state.ParamInputDesc}
                  onChange={this.handleParamInput}
                  margin="normal"
                />
                
              </form>
            </CardContent>
            <CardActions align="end">
              <Button variant="contained" color="primary" onClick={this.handleCloseModalparam} className={classes.right}>  Cancel </Button>
              <Button variant="contained" color="primary" onClick={this.submitParam}>  Submit </Button>
            </CardActions>
          </Card>
        </div>
      </Modal>
    )

  }


  submitParamVersion = (event) => {
    event.preventDefault()

    let dataToSubmit = {
      "param_uuid" : this.state.parameterSelected.param_uuid,
      "appVersion" : this.state.ParamInputVersion
    }

    console.log("submit " + JSON.stringify(dataToSubmit))

    const urlparams = urls.getApiParameters()+'/param/version'

    let options = {
      method: 'POST',
      body:    JSON.stringify(dataToSubmit),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

      fetch(urlparams, options)
      .then(async response => {
        if (!response.ok) {
          let resp = await response.json()
          if(resp && resp.error && resp.error.msg) {
            throw Error(resp.error.msg)
          } else {
            throw Error("Network request failed")
          }
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {

        // Reset formulaire et close Modal
        this.setState({
          ModalParamVersionOpen: false,
          ParamInputVersion : null
        })

        // Appel Api to reload the table NOT EFFICIENT WAY ...
        this.fetchParamVersion(this.state.parameterSelected.param_uuid)

      }).catch( (err) => {
          alert(err);
      })

  }


  deleteParamVersion = (version_uuid) => {
    
    const urlparams = urls.getApiParameters()+'/param/version/'+version_uuid

    let options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

      fetch(urlparams, options)
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {

        // Appel Api to reload the table NOT EFFICIENT WAY ...
        this.fetchParamVersion(this.state.parameterSelected.param_uuid)

      })

  }

  displayModalParamVersion = () => {

    const { classes } = this.props;

    return (

      <Modal
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        open={this.state.ModalParamVersionOpen}
        onClose={this.handleCloseModalParamVersion}
      >
        <div className={classes.modalParam}>

          <Card className={classes.card}>
            <CardHeader
              title={(this.state.parameterSelected) ? "Add a version to " + this.state.parameterSelected.param_key : ''}
            />
            <CardContent>
              <form className={classes.container} noValidate autoComplete="off" >

                <TextField
                  id="ParamInputParamSelected"
                  label="Parameter"
                  inputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  className={classes.textField}
                  value={(this.state.parameterSelected) ? this.state.parameterSelected.param_uuid : ''}
                  onChange={this.handleParamInput}
                  margin="normal"
                />

                <TextField
                  id="ParamInputVersion"
                  label="Version"
                  className={classes.textField}
                  value={this.state.ParamInputVersion}
                  onChange={this.handleParamInput}
                  margin="normal"
                />
                
              </form>
            </CardContent>
            <CardActions align="end">
              <Button variant="contained" color="primary" onClick={this.handleCloseModalParamVersion} className={classes.right}>  Cancel </Button>
              <Button variant="contained" color="primary" onClick={this.submitParamVersion}>  Submit </Button>
            </CardActions>
          </Card>
        </div>
      </Modal>
    )

  }


  submitParamValue = (event) => {
    event.preventDefault()

    let dataToSubmit = {

      value_uuid: this.state.versionSelected.value_uuid,
      entity : this.state.ParamInputEntity,
      type_entity : this.state.ParamInputType_entity,
      value_string : this.state.ParamInputValue_string
    }

    console.log("submit " + JSON.stringify(dataToSubmit))

    const urlparams = urls.getApiParameters()+'/param/value'

    let options = {
      method: 'POST',
      body:    JSON.stringify(dataToSubmit),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

      fetch(urlparams, options)
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {

        // Reset formulaire et close Modal
        this.setState({
          ModalParamValueOpen: false,
          ParamInputEntity : null,
          ParamInputType_entity : null,
          ParamInputValue_string : null
        })

        // Appel Api to reload the table NOT EFFICIENT WAY ...
        this.fetchParamValue(this.state.versionSelected.value_uuid)

      })

  }

  deleteParamValue = (param_uuid, entity) => {
    
    const urlparams = urls.getApiParameters()+'/param/value/'+param_uuid+'/'+entity

    let options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      }
    }

      fetch(urlparams, options)
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        console.log(JSON.stringify(response))
        return response
      })
      .then(d => d.json())
      .then(d => {

        // Appel Api to reload the table NOT EFFICIENT WAY ...
        this.fetchParamValue(this.state.versionSelected.value_uuid)

      })

  }

  displayModalParamValue = () => {

    const { classes } = this.props;

    return (

      <Modal
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        open={this.state.ModalParamValueOpen}
        onClose={this.handleCloseModalParamValue}
      >
        <div className={classes.modalParam}>

          <Card className={classes.card}>
            <CardHeader
              title={(this.state.versionSelected) ? "Add a value to param : " + this.state.parameterSelected.param_key +" and version : " + this.state.versionSelected.version : ''}
            />
            <CardContent>
              <form className={classes.container} noValidate autoComplete="off" >

                <TextField
                  id="ParamInputVersionSelected"
                  label="Parameter"
                  inputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  className={classes.textField}
                  value={(this.state.versionSelected) ? this.state.versionSelected.value_uuid : ''}
                  onChange={this.handleParamInput}
                  margin="normal"
                />

                <TextField
                  id="ParamInputEntity"
                  label="Entity"
                  className={classes.textField}
                  value={this.state.ParamInputEntity}
                  onChange={this.handleParamInput}
                  margin="normal"
                />

                <TextField
                  id="ParamInputType_entity"
                  label="Type entity"
                  className={classes.textField}
                  value={this.state.ParamInputType_entity}
                  onChange={this.handleParamInput}
                  margin="normal"
                />

                <TextField
                  id="ParamInputValue_string"
                  label="Value"
                  className={classes.textField}
                  value={this.state.ParamInputValue_string}
                  onChange={this.handleParamInput}
                  margin="normal"
                />
                
              </form>
            </CardContent>
            <CardActions align="end">
              <Button variant="contained" color="primary" onClick={this.handleCloseModalParamValue} className={classes.right}>  Cancel </Button>
              <Button variant="contained" color="primary" onClick={this.submitParamValue}>  Submit </Button>
            </CardActions>
          </Card>
        </div>
      </Modal>
    )

  }


  render() {
    const { classes } = this.props;

    const rows = this.state.paramApp.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
    const rowsVersion = this.state.paramsVersion
    const rowsValue = this.state.paramsValue



    return (


      <div>

        <Paper className={classes.paper}>
          <Grid container alignItems="center">
            <Grid item xs={1}>
              <img src={"https://d34k2c2dsncg2r.cloudfront.net/" + this.state.app + "/icon_app.png"} alt={this.state.app} width="50" height="50"/> 
            </Grid>
            <Grid item xs={3}>
              {this.state.app}
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={2}>
              <Button variant="contained" color="primary" onClick={this.fetchApi}>  Get parameters </Button>
            </Grid>
          </Grid>
        </Paper>

        {rows.length > 0 && 
         <Paper className={classes.paper}>
        <Card className={classes.card}>
            <CardHeader
              title="Parameters availables"
              action={
                <Fab color="primary" aria-label="add" className={classes.fab} size="small" onClick={this.openModalParam}>
                  <AddIcon />
                </Fab> 
              }
            />
            <CardContent>

            <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell> Key </TableCell>
                <TableCell align="left"> Type </TableCell>
                <TableCell align="left"> Description </TableCell>
                <TableCell align="center"> View/Edit </TableCell>
                <TableCell align="center"> &nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.param_uuid}>
                  <TableCell component="th" scope="row" align="left">{row.param_key}</TableCell>
                  <TableCell align="left">{row.type_param}</TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                  <TableCell align="center" className={classes.pointerClic} onClick={() => this.displayParamVersion(row.param_uuid)}> <VisibilityIcon  color="primary"  className={classes.pointerClic}/>  </TableCell>
                  <TableCell align="right" > <DeleteIcon  color="error" className={classes.pointerClic} onClick={() => this.deleteParam(row.param_uuid)}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 100, 1000]}
            component="div"
            count={this.state.paramApp.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />

            </CardContent>
           </Card> 
        </Paper>
        }
        
        
        {this.state.parameterSelected && 
        <Paper className={classes.paper}>
          <Card className={classes.card}>
            <CardHeader
              title={this.state.parameterSelected ? "Parameter : " + this.state.parameterSelected.param_key : " "}
              action={
                <Fab color="primary" aria-label="add" className={classes.fab} size="small" onClick={this.openModalParamVersion}>
                  <AddIcon />
                </Fab> 
              }
            />
            <CardContent>
            <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left"> Version </TableCell>
                <TableCell align="center"> &nbsp;&nbsp; </TableCell>
                <TableCell align="center"> &nbsp;&nbsp; </TableCell>
                <TableCell align="center"> &nbsp;&nbsp; </TableCell>
                <TableCell align="center"> &nbsp;&nbsp;</TableCell>
                <TableCell align="center"> &nbsp;&nbsp;</TableCell>
                <TableCell align="center"> &nbsp;&nbsp;</TableCell>
                <TableCell align="center"> View/Edit </TableCell>
                <TableCell align="center"> &nbsp; </TableCell>
              </TableRow  >
            </TableHead>
            <TableBody>
              {rowsVersion.map(row => (
                <TableRow key="param_version">
                  <TableCell component="th" scope="row" align="left">{row.version}</TableCell>
                  <TableCell align="center"> &nbsp;&nbsp; </TableCell>
                  <TableCell align="center"> &nbsp;&nbsp; </TableCell>
                  <TableCell align="center"> &nbsp;&nbsp; </TableCell>
                  <TableCell align="center"> &nbsp;&nbsp; </TableCell>
                  <TableCell align="center"> &nbsp;&nbsp;</TableCell>
                  <TableCell align="center"> &nbsp;&nbsp;</TableCell>
                  <TableCell align="center" className={classes.pointerClic} onClick={() => this.displayParamValue(row.value_uuid)}> <VisibilityIcon  color="primary"  className={classes.pointerClic}/>  </TableCell>
                  <TableCell align="right" > <DeleteIcon  color="error" className={classes.pointerClic} onClick={() => this.deleteParamVersion(row.value_uuid)}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
            </CardContent>
          </Card>
        </Paper>
        }

        
        {this.state.versionSelected && 
        <Paper className={classes.paper}>
        <Card className={classes.card}>
          <CardHeader
            title={this.state.versionSelected ? this.state.parameterSelected.param_key + " | Version : " + this.state.versionSelected.version : " "}
            action={
              <Fab color="primary" aria-label="add" className={classes.fab} size="small" onClick={this.openModalParamValue}>
                <AddIcon />
              </Fab> 
            }
          />
          <CardContent>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left"> Type_entity </TableCell>
                <TableCell align="left"> Entity </TableCell>
                <TableCell align="left"> Value </TableCell>
                <TableCell align="left"> Date update </TableCell>
                <TableCell align="left"> User update </TableCell>
                <TableCell align="center"> &nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsValue.map(row => (
                <TableRow key="param_value">
                  <TableCell align="left"> <div className={classes.hiden}> {row.value_uuid} </div>{row.type_entity}  </TableCell>
                  <TableCell align="left"> {row.entity}  </TableCell>
                  <TableCell align="left"> {row.value_string}  </TableCell>
                  <TableCell align="left"> {row.date_update}  </TableCell>
                  <TableCell align="left"> {row.user_update}  </TableCell>
                  <TableCell align="right" > <DeleteIcon  color="error" className={classes.pointerClic} onClick={() => this.deleteParamValue(row.value_uuid, row.entity)}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </CardContent>
        </Card>
        </Paper>
        }
        

        <div>{this.displayModalParam("app")} </div>
        <div>{this.displayModalParamVersion()} </div>
        <div>{this.displayModalParamValue()} </div>

      </div>



    );

  }

}

ManageParams.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ManageParams);
